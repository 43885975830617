import React from "react"
import styles from "../../styles/modules/static-pages.module.scss"
import ButtonCustom from "../ButtonCustom"
import { navigate } from "@reach/router"

const OurTeam = () => (
  <section className={`wrapper wrapper--left`}>
    <h2 className={`x-large-body-copy ${styles.title}`}>Meet NeighborShare</h2>
    <div>
      <h3 className={`header`}>Our Mission</h3>
      <p className={`body-copy ${styles.mediumParagraph}`}>
        NeighborShare connects people who need help with people who want to
        help. <br />
        <br />
        We do this by partnering with community heroes— case workers, social
        workers, etc. who are on the ground in communities —to identify pivotal
        needs of $400 or less that would otherwise go unmet. When local options
        have run out, we use our platform to connect those critical needs with
        our national community of donors who want to help.
      </p>
    </div>
    <div>
      <h3 className={`header`}>Our Values</h3>
      <ul className={`body-copy ${styles.mediumParagraph}`}>
        <li>
          <b>Empowerment.</b> We know that the community heroes on the ground
          know their communities best, so our goal is to enable them to maximize
          their impact, not direct it.
        </li>
        <li>
          <b>Dignity.</b> The people we help are our neighbors. We treat each
          story, each community hero, and each partner organization with empathy
          and respect.
        </li>
        <li>
          <b>Community.</b> We believe that neighbors are everywhere and that
          humanity is at its best when we look out for each other. Today you’re
          helping someone—tomorrow you may need their help. We pay it back and
          we pay it forward.
        </li>
        <li>
          <b>Transparency.</b> Donors, community heroes, and partner
          organizations all know where money given to NeighborShare comes from
          and where it goes.
        </li>
      </ul>
    </div>
  </section>
)

export default OurTeam
