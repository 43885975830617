import React from "react"
import { useQuery } from "@apollo/client"
import { GET_TEAM } from "../queries/getTeam"
import TeamGrid from "../components/TeamGrid"
import OpenRolesGrid from "../components/OpenRolesGrid"
import Loading from "../components/Loading"
import FamilySuggestions from "../components/FamilySuggestions"
import SEO from "../components/SEO"
import OurTeam from "../components/OurTeam"

const Team = () => {
  const { loading, data } = useQuery(GET_TEAM)
  return (
    <>
      <SEO title="Our Team" description="Different stories, shared community" />
      <OurTeam />
      {loading && <Loading />}
      {!loading && data && (
        <div id="team-members">
          <TeamGrid teamMembers={data.team_members} />
          <OpenRolesGrid />
          <FamilySuggestions title="Meet families in need" />
        </div>
      )}
    </>
  )
}

export default Team
