import React, { useState, useEffect } from "react"

import GridSection from "../GridSection"
import ButtonCustom from "../ButtonCustom"

import { EmailIcon, LinkedinIcon, TwitterIcon } from "react-share"
import styles from "./styles.module.scss"
import { teamRoleType } from "../../constants/team-role-types"
import LinkCustom from "../LinkCustom"
import DropdownCustom from "../DropdownCustom"

// const EVERYONE = "EVERYONE"

const TeamGrid = ({ teamMembers }) => {
  const [isMobile, setIsMobile] = useState(null)
  const resizeListener = () => setIsMobile(window.innerWidth < 1024)

  useEffect(() => {
    setIsMobile(window && window.innerWidth < 1024)
    window && window.addEventListener("resize", resizeListener)
    return () => window.removeEventListener("resize", resizeListener)
  }, [])
  {
    /* Code for previous team page and its filtering functions
  const allFalseBut = roleType =>
    Object.keys(teamRoleType).reduce((e, i) => {
      e[i] = i === roleType
      return e
    }, {})

  const [selectedTypes, setSelectedTypes] = useState(allFalseBut(EVERYONE))
  const [selectedData, setSelectedData] = useState(teamMembers)

  useEffect(() => {
    // This is an object that we're wrangling so we can do some multi-select stuff if people want
    // e.g., leadership + product -- but disabled for now.
    let activeFilters = Object.entries(selectedTypes)
      .filter(selected => selected[1] === true)
      .map(selected => selected[0])
    if (activeFilters.length === 0) {
      selectType(EVERYONE)
    }
    if (activeFilters.includes(EVERYONE)) {
      setSelectedData(teamMembers)
    } else {
      setSelectedData(
        teamMembers.filter(tm =>
          tm.team_member_categories.some(cat =>
            activeFilters.includes(cat.role_category)
          )
        )
      )
    }
  }, [selectedTypes, teamMembers])

  const selectType = roleType => {
    setSelectedTypes(allFalseBut(roleType))
  }

  const selectedTypeNames = () =>
    Object.entries(selectedTypes)
      .filter(elt => elt[1] === true)
      .map(elt => elt[0])
*/
  }

  if (teamMembers.length === 0) return null

  // const Filters = () => {
  //   if (isMobile) {
  //     return (
  //       <DropdownCustom
  //         text={teamRoleType[selectedTypeNames()]} // will break if we switch to multi-select
  //         items={Object.entries(teamRoleType).map(roleType => ({
  //           text: roleType[1],
  //           value: roleType[0],
  //           selected: selectedTypes[roleType[0]] === true,
  //         }))}
  //         onSelect={team => selectType(team.pop())} // will break
  //       />
  //     )
  //   } else {
  //     return (
  //       <div className={styles.roleFilter}>
  //         {Object.entries(teamRoleType).map(roleType => (
  //           <ButtonCustom
  //             size="small"
  //             role="button"
  //             hierarchy={selectedTypes[roleType[0]] ? "dark" : "transparent"}
  //             className={styles.roleControl}
  //             onClick={() => selectType(roleType[0])}
  //           >
  //             {roleType[1]}
  //           </ButtonCustom>
  //         ))}
  //       </div>
  //     )
  //   }
  // }

  const teamData = {
    STAFF: teamMembers.filter(person =>
      person.team_member_categories.some(cat => cat.level_category === "STAFF")
    ),
    BOARD: teamMembers.filter(person =>
      person.team_member_categories.some(cat => cat.level_category === "BOARD")
    ),
    ADVISORS: teamMembers.filter(person =>
      person.team_member_categories.some(
        cat => cat.level_category === "ADVISOR"
      )
    ),
    VOLUNTEERS: teamMembers.filter(person =>
      person.team_member_categories.some(
        cat => cat.level_category === "VOLUNTEER"
      )
    ),
  }

  Object.values(teamData).forEach(team => {
    team.sort((a, b) => a.name.localeCompare(b.name))
    team.sort((a, b) => (b.sort_priority || -1) - (a.sort_priority || -1))
  })

  return (
    <GridSection
      title={`Our Team`}
      description={
        "The NeighborShare movement was created by the time, energy, and expertise of a national team of volunteers. Much of the work that happens today is still driven by this dedicated group of professionals who are experts in their fields. Meet the team!"
      }
      bgColor="cream"
      // filters={<Filters />}
    >
      {Object.entries(teamData).map(entry => (
        <div className={styles.gridCard} key={entry[0]}>
          <br />
          <h1>
            <b>{entry[0]}</b>
          </h1>
          <ul>
            {entry[1].map(person => (
              <li key={person.name}>
                <Member {...person} />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </GridSection>
  )
}

const Member = ({
  name,
  role,
  headshot_url,
  linkedin_url,
  twitter_url,
  email,
}) => (
  <article className={styles.member}>
    {headshot_url && (
      <img src={headshot_url} alt={name} className={styles.headShot} />
    )}
    <div className={styles.information}>
      <h1 className="small-header">{name}</h1>
      <p className={`tiny-body-copy ${styles.role}`}>{role}</p>
      <div>
        {email && (
          <LinkCustom size="nav" url={`mailto:${email}`} scope="external">
            <EmailIcon round={true} size={25} />
          </LinkCustom>
        )}
        {linkedin_url && (
          <LinkCustom size="nav" url={linkedin_url} scope="external">
            <LinkedinIcon round={true} size={25} />
          </LinkCustom>
        )}
        {twitter_url && (
          <LinkCustom size="nav" url={twitter_url} scope="external">
            <TwitterIcon round={true} size={25} />
          </LinkCustom>
        )}
      </div>
    </div>
  </article>
)

export default TeamGrid
