import { gql } from "@apollo/client"

export const GET_TEAM = gql`
  query getTeamMembers {
    team_members {
      name
      role
      sort_priority
      headshot_url
      linkedin_url
      twitter_url
      email
      team_member_categories {
        level_category
        role_category
      }
    }
  }
`
